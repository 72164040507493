/* eslint-disable react/jsx-props-no-spreading */
import MainLoader from "components/helpers/MainLoader";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter, Routes, Navigate, useNavigate } from "react-router-dom";
import routePaths from "routes/routePaths";
import { getCurrentUser } from "store/reducers/session";

// lazy rendering components
const Login = React.lazy(() => import("pages/Login"));
const Main = React.lazy(() => import("pages/Main"));
const Search = React.lazy(() => import("pages/Search"));
const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const Project = React.lazy(() => import("pages/Project"));
const ComparisonTable = React.lazy(() => import("pages/ComparisonTable"));
const KDeviceView = React.lazy(() => import("pages/510kDeviceView"));

const AuthRoute: React.FC<{ children: JSX.Element }> = ({
    children,
  }: {
    children: JSX.Element;
  }) => {
    const user = useSelector(getCurrentUser);
    const navigate = useNavigate();
    const [authorized, setAuthorized] = useState<boolean>(false);

    useEffect(() => {
        if(user) {
            setAuthorized(true);
        } else {
            navigate(routePaths.login)
        }
    }, [user])

    if (!authorized) {
        return <MainLoader />;
      }
  
    return children;
};


const Router: React.FC = () => {
  return (
    <Suspense fallback={<MainLoader />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={<Navigate to={routePaths.main} replace />}
          />
          <Route path={routePaths.default} element={<Navigate to={routePaths.main} replace />} />
          <Route
            path={`${routePaths.main}`}
            element={
              <AuthRoute>
                <Main />
              </AuthRoute>
              }
          >
            <Route path="" element={<Navigate to="search" replace />} />
            <Route path="search" element={<Search />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="project/:id" element={<Project />} />
            <Route path="comparison_table/:id" element={<ComparisonTable />} />
            <Route path="510k_device/:id" element={<KDeviceView />} />
          </Route>
          <Route
            path={`${routePaths.login}`}
            element={<Login />}
          />
          <Route
            path={`${routePaths.reset_password}`}
            element={<ResetPassword />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;