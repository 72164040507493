const routePaths = {
    default: "/",
    login: "/login",
    reset_password: "/reset_password",
    main: "/main",
    search: "/main/search",
    dashboard: "/main/dashboard",
    project: "/main/project/:id",
    comparison_table: "/main/comparison_table/:id",
    k_device: "/main/510k_device/:id",
  };
  
  export default routePaths;
  