/* eslint-disable import/no-extraneous-dependencies */

import { configureStore } from '@reduxjs/toolkit';
import { 
    persistStore, 
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    createMigrate
} from 'redux-persist';
import localForage from 'localforage'
import rootReducer from './reducers';
import { initialKDevicesDashboardFilters } from './reducers/utils';

const migrations = {
  1: (state:any) =>
    ({
      ...state,
    }),
  // add alerts
  2: (state:any) => ({
    ...state,
    utils: {
      ...state.utils,
      alerts: []
    }
  }),
  // add Kdevices dashboard filters
  3: (state:any) => ({
    ...state,
    utils: {
      ...state.utils,
      dashboardFilters: {
        kDevices: initialKDevicesDashboardFilters
      }
    }
  })
}

const persistConfig = {
  key: "root",
  storage: localForage,
  timeout: 20000,
  version: 3,
  whitelist: ["session", "utils"],
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;