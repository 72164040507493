/* eslint-disable import/no-extraneous-dependencies */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccessTokenResponse } from "models/api/response.types";
import { IState } from ".";

export interface SessionState {
  user: AccessTokenResponse | undefined;
}

export const initialState: SessionState = {
  user: undefined,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AccessTokenResponse>) {
      state.user = action.payload;
    },
  },
});

export const { reducer } = sessionSlice;
export const { setUser } = sessionSlice.actions;

export const getCurrentUser = createSelector(
  (state: IState) => state.session.user,
  (item) => item,
);
