import React from "react";
import LoadingOverlay from "./LoadingOverlay";

const MainLoader:React.FC = () => {
    return (
      <div className="fixed w-full flex h-full z-50 bg-slate-100 items-center justify-center">
        <LoadingOverlay />
      </div>
    )
}

export default MainLoader;