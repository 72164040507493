import React from 'react';
import "./index.css";
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/reducers/session';
import axios from 'axios';
import Router from 'routes/Router';

const App:React.FC = () => {
  const user = useSelector(getCurrentUser);

  // set default authorization header to use JWT token
  axios.defaults.headers.common.Authorization = user
    ? `Bearer ${user.access_token}`
    : "";

  return (
    <Router />
  );
}

export default App;
