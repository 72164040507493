/* eslint-disable import/no-extraneous-dependencies */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IState } from ".";

export interface Alert {
  id: string;
  severity: "success" | "info" | "warning" | "error";
  message:string;
}

export type Order = "asc" | "desc";
export type OrderData = "relevance" | "device_name" | "k_number" | "product_code" | "decision_date" | "applicant" | "time_as_predicate";

export const defaultColumnsToEnable = [
    "device_name",
    "k_number", 
    "product_code",
    "decision_date",
    "applicant",
    "summary",
    "intended_use", 
];

export const initialFilteredData = {
  device_name: "",
  k_number: "",
  code: "",
  acceppted_from: "",
  accepted_to: "",
  applicant: ""
}

export const initialKDevicesDashboardFilters = {
  device_name: "",
  k_number: "",
  product_code: "",
  decision_date_from: "",
  decision_date_to: "",
  applicant: "",
  isWithinYear: false
}

export interface UtilsState {
  alerts: Alert[],
  showHideColumns: {
    [k:string]: string[]
  },
  sorts: {
    [k:string]: {
        order: Order,
        orderBy: OrderData
    }
  },
  filters: {
    [k:string]: {[key:string]:string}
  },
  dashboardFilters: {
    [k:string]: {[key:string]:any}
  }
}

export const initialState: UtilsState = {
  alerts: [],
  showHideColumns: {
    search: defaultColumnsToEnable
  },
  sorts: {
    search: {
        order: "asc",
        orderBy: "relevance"
    }
  },
  filters: {
    search: initialFilteredData
  },
  dashboardFilters: {
    kDevices: initialKDevicesDashboardFilters
  }
};

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setShowHideColumns(state, action: PayloadAction<{projectId:number | string, columns: string[]}>) {
      const {projectId, columns} = action.payload
      state.showHideColumns[projectId] = columns;
    },
    setSortOrder(state, action: PayloadAction<{projectId:number | string, sort: {order:Order, orderBy: OrderData}}>) {
        const {projectId, sort} = action.payload
        state.sorts[projectId] = sort;
    },
    setFilters(state, action: PayloadAction<{projectId:number | string, filters:{[key:string]:string}}>) {
        const {projectId, filters} = action.payload
        state.filters[projectId] = filters;
    },
    setDashboardFilters(state, action: PayloadAction<{type:string, filters:{[key:string]:any}}>) {
      const {type, filters} = action.payload
      state.dashboardFilters[type] = filters;
    },
    addAlert(state, action:PayloadAction<Alert>) {
      state.alerts = [...state.alerts, action.payload]
    },
    removeAlert(state, action:PayloadAction<string>){
      const newAlerts = state.alerts.filter(alert => alert.id !== action.payload);
      state.alerts = newAlerts
    }
  },
});

export const { reducer } = utilsSlice;
export const { 
  setShowHideColumns, 
  setSortOrder, 
  setFilters, 
  addAlert, 
  removeAlert,
  setDashboardFilters
} = utilsSlice.actions;

const getShowHideColumns = createSelector(
  (state: IState) => state.utils.showHideColumns,
  (item) => item,
);

export const getShowHideColumnsById = createSelector(
  getShowHideColumns,
  (_:any, itemId: number | string) => itemId,
  (items, projectId) => {
      return items[projectId]
  }
);

const getSorts = createSelector(
    (state: IState) => state.utils.sorts,
    (item) => item,
  );
  
export const getSortById = createSelector(
    getSorts,
    (_:any, itemId: number | string) => itemId,
    (items, projectId) => {
        return items[projectId]
    }
);

const getFilters = createSelector(
    (state: IState) => state.utils.filters,
    (item) => item,
);

export const getDashboardFilters = createSelector(
  (state: IState) => state.utils.dashboardFilters,
  (item) => item,
)

  
export const getFilterById = createSelector(
    getFilters,
    (_:any, itemId: number | string) => itemId,
    (items, projectId) => {
        return items[projectId]
    }
);

export const getAlerts = createSelector(
  (state: IState) => state.utils.alerts,
  (item) => item,
);
