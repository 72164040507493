/* eslint-disable import/no-extraneous-dependencies */
import { AnyAction, CombinedState, combineReducers } from "@reduxjs/toolkit";
import { SessionState, reducer as session } from "./session";
import { UtilsState, reducer as utils } from "./utils";

export interface IState {
  session: SessionState;
  utils: UtilsState
}

const reducers = combineReducers({
  session,
  utils
});

const rootReducer = (
  state: CombinedState<IState> | undefined,
  action: AnyAction,
): CombinedState<IState> => {
  if (action.type === "session/cleanup") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
