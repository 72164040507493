import React from "react";

const LoadingOverlay:React.FC = () => {
    return (
      <span className="relative flex h-10 w-10">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75" />
        <span className="relative inline-flex rounded-full h-10 w-10 bg-blue-500" />
      </span>
    )
}

export default LoadingOverlay;