import React from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux';
import MainLoader from 'components/helpers/MainLoader';
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModuleRegistry, Module } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export"
import { persistor, store } from 'store';
import App from './App';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 120000,
      refetchOnReconnect: true,
    },
  },
});

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  GridChartsModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  MenuModule,
  RangeSelectionModule,
  RowGroupingModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
] as Module[]);
const licenseKey =
  "CompanyName=Paladin Max, Inc.,LicensedGroup=KaleidoGlobe,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=2,AssetReference=AG-022371,ExpiryDate=16_December_2022_[v2]_MTY3MTE0ODgwMDAwMA==8a498e53c0cc3997bad03109ea7e2f94";
const cleanedLicenseKey = licenseKey.replace(/[\u200B-\u200D\uFEFF]/g, "");
LicenseManager.setLicenseKey(cleanedLicenseKey);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate 
        loading={
          <MainLoader />
        } 
        persistor={persistor}
      >
        <App />
      </PersistGate>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </Provider>
);

